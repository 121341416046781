import { Box, Center, Container, HStack, Image, Link, SimpleGrid } from '@chakra-ui/react';
import React from 'react';
import Layout from '../../components/Layout';
import SEO from '../../components/seo';
import { ContactHero } from '../../components/shared';
import { useContactCommercialPage } from '../../GraphQL/Queries';

const ContactUs = ({ location }) => {
  const data = useContactCommercialPage();

  const { acf_hero, acf_map_location, acf_contact_information } = data.pageBy;

  return (
    <Layout location={location}>
      <SEO title={acf_hero.heroTitle} />
      <ContactHero
        isCommercial
        contactBlurb={acf_hero.heroDescription}
        ctaText="Send Form"
        image={acf_hero.heroImage.sourceUrl}
      />
      <Box height={{ base: '200px', md: '386px' }} zIndex="-1">
        <iframe
          title="Maps"
          width="100%"
          height="100%"
          frameBorder="0"
          scrolling="yes"
          marginHeight="0"
          marginWidth="0"
          src={acf_map_location.locationUrl}
        />
      </Box>
      <Box>
        <Container>
          <SimpleGrid minChildWidth="330px" columns={3} py={{ base: '1rem', md: 'unset' }}>
            <Box height={{ base: '80px', md: '110px' }}>
              <Center
                height="100%"
                w="100%"
                justifyContent={{ base: 'flex-start', md: 'unset' }}
                pl={{ base: '2rem', md: 'unset' }}
              >
                <HStack spacing="1rem" role="group" w="100%">
                  <Image
                    src="/icons/call-us.svg"
                    _groupHover={{
                      filter: 'invert(39%) sepia(67%) saturate(3854%) hue-rotate(3deg) brightness(103%) contrast(103%)',
                    }}
                  />
                  <Link
                    fontSize="16px"
                    fontWeight="bold"
                    _groupHover={{ color: 'secondary', cursor: 'pointer', textDecoration: 'none' }}
                    color="primary"
                    href={'tel:' + acf_contact_information.phoneNumber}
                  >
                    {acf_contact_information.phoneNumber}
                  </Link>
                </HStack>
              </Center>
            </Box>
            <Box height={{ base: '80px', md: '110px' }}>
              <Center
                height="100%"
                justifyContent={{ base: 'flex-start', md: 'unset' }}
                pl={{ base: '1.6rem', md: 'unset' }}
              >
                <HStack spacing="1rem" role="group">
                  <Image
                    src="/icons/mail-us.svg"
                    _groupHover={{
                      filter: 'invert(39%) sepia(67%) saturate(3854%) hue-rotate(3deg) brightness(103%) contrast(103%)',
                    }}
                  />
                  <Link
                    fontSize="16px"
                    fontWeight="bold"
                    _groupHover={{ color: 'secondary', cursor: 'pointer', textDecoration: 'none' }}
                    color="primary"
                    href={'mailto:' + acf_contact_information.email}
                  >
                    {acf_contact_information.email}
                  </Link>
                </HStack>
              </Center>
            </Box>
            <Box height={{ base: '80px', md: '110px' }}>
              <Center
                height="100%"
                justifyContent={{ base: 'flex-start', md: 'unset' }}
                pl={{ base: '2rem', md: 'unset' }}
                maxW={{ base: '250px', md: 'unset' }}
              >
                <HStack spacing="1rem" role="group">
                  <Image
                    src="/icons/address.svg"
                    _groupHover={{
                      filter: 'invert(39%) sepia(67%) saturate(3854%) hue-rotate(3deg) brightness(103%) contrast(103%)',
                    }}
                  />
                  <Link
                    fontSize="16px"
                    fontWeight="bold"
                    _groupHover={{ color: 'secondary', cursor: 'pointer', textDecoration: 'none' }}
                    color="primary"
                    href={'https://maps.google.com/?q=' + acf_contact_information.address}
                    isExternal
                  >
                    {acf_contact_information.address}
                  </Link>
                </HStack>
              </Center>
            </Box>
          </SimpleGrid>
        </Container>
      </Box>
    </Layout>
  );
};

export default ContactUs;
